<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
  },
}
</script>
<style rel="stylesheet/scss" lang="scss">
.app-main > div {
  padding: 0;
}

.t-box {
  display: flex;
}
.t-box-flex1 {
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  box-flex: 1;
  width: auto;
}
.t-box-flex2 {
  -moz-box-flex: 2;
  -webkit-box-flex: 2;
  box-flex: 2;
  width: auto;
}
.t-box-flex3 {
  -moz-box-flex: 3;
  -webkit-box-flex: 3;
  box-flex: 3;
  width: auto;
}
.t-box-center {
  box-align: center;
}
.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.c-theme {
  color: #000 !important;
}
#app {
  .el-pager li.active {
    color:#409eff !important
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #409eff !important;
    border-color: #409eff !important;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #409eff !important;
    background: #409eff !important;
}
.el-radio__input.is-checked .el-radio__inner:after {
    transform: translate(-50%,-50%) scale(1) !important;
}
.el-radio__inner:after {
    background-color: #fff !important;
}
}
.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
    color: #409eff !important;
}
</style>
